<template>
  <div>
    <vs-table 
      search 
      stripe 
      border 
      description 
      :sst="true" 
      :data="table.data" 
      :max-items="table.length"
      :total="table.total" 
      @search="handleSearch" 
      @change-page="handleChangePage" 
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
             v-for="item in table.limits" 
             :key="item" 
             @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th width="10%"></vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th>Customer</vs-th>
        <vs-th sort-key="address">Address</vs-th>
        <vs-th sort-key="map_address">Map</vs-th>
        <vs-th sort-key="contact_name">Contact</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td class="whitespace-no-wrap">
            <feather-icon 
              title="Edit" 
              icon="EditIcon" 
              svgClasses="w-5 h-5 hover:text-primary stroke-current" 
              @click.stop="
              handleEdit(
                data[indextr].id ? data[indextr].id : data[indextr].ID,
                tr.status_approval
              )
            " />
            <feather-icon v-if="tr.status_approval == '0' && showApproval" title="Send for Approval" icon="SendIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="
                handleSendForApproval(
                  data[indextr].id ? data[indextr].id : data[indextr].ID
                )
            "/>
          </vs-td>
          <vs-td :data="data[indextr].status">
            <div v-if="data[indextr].status">
              <div v-for="item in JSON.parse(data[indextr].status)" :key="item.day">
                {{ item }}
              </div>
            </div>
          </vs-td>
          <vs-td>
            Code : <b>{{ data[indextr].customer.code }}</b>
            <br />
            Name : <b>{{ data[indextr].customer.name }}</b>
            <br />
          </vs-td>
          <vs-td :data="data[indextr].code">
            Code :
            <b>{{ data[indextr].code }}</b><br>
            Code NOO :
            <b>{{ data[indextr].code_noo }}</b>
            <br>Name : <b>{{ data[indextr].customer.name }}</b>
            <br />Region :
            <b>{{ data[indextr].territory.territory_area.Code }}
              {{ data[indextr].territory.territory_area.Name }}</b>
            <br />Territory :
            <b>{{ data[indextr].territory.code }}
              {{ data[indextr].territory.name }}</b>
            <br />Zone :
            <b>{{ data[indextr].zone.code }}
              {{ data[indextr].zone.name }}</b>
            <br />Sales :
            <b>{{ data[indextr].sales.WorkIDNumber }}
              {{ data[indextr].sales.Name }}</b>
            <br /><br />Address :
            <b>{{ data[indextr].address }}</b>
            <br />Subdistrict :
            <b>{{ data[indextr].sub_district }}</b>
            <br />District :
            <b>{{ data[indextr].district }}</b>
            <br />City :
            <b>{{ data[indextr].city }}</b>
            <br />Province :
            <b>{{ data[indextr].province }}</b>
            <br />Country :
            <b>{{ data[indextr].country }}</b>
            <br />Postal Code :
            <b>{{ data[indextr].postal_code }}</b>
            <br />Status :
            <b>{{ data[indextr].status_active }}</b>
            <br />Status Order Block:
            <b v-if="tr.shiping_block == 0">-</b>
            <b v-else-if="tr.shiping_block == 1">Order Block</b>
            <b v-else-if="tr.shiping_block == 2">Return Block</b>
            <b v-else-if="tr.shiping_block == 3">All Block</b>
            <br />
            Status Approval : {{ data[indextr].string_status_approval }}
            <br />
            Reason : {{ data[indextr].remark_approval }}
          </vs-td>
          <vs-td :data="data[indextr].code">
            <br />Lat :
            <b>{{ data[indextr].latitude }}</b>
            <br />Long :
            <b>{{ data[indextr].longitude }}</b>
          </vs-td>
          <vs-td :data="data[indextr].contact_name">
            Name :
            <b>{{ data[indextr].customer.nik_name }}</b>
            <br />Phone :
            <b>{{ data[indextr].phone }}</b>
            <br />Mobile :
            <b>{{ data[indextr].contact_mobile }}</b>
            <br />Email :
            <b>{{ data[indextr].email }}</b>
            <br />Fax :
            <b>{{ data[indextr].fax }}</b>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination 
      style="padding-top: 5px" 
      :total="table.totalPage" 
      v-model="setPage" 
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    territorryId: {
      type: Number,
    },
    detail: {
      type: Boolean,
    },
    statusFilter: {
      type: Number,
    },
    statusApproval: {
      type: String,
    },
    statusFrom: {
      type: String,
    },
  },
  data() {
    return {
      baseUrl: "/api/oms/v1/sfa-master/customer-address",
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleCreate() {
      this.customerAddressId = null;
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-sfa", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            status_approval: this.statusApproval
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.data);
          }
        });
    },
    handleSendForApproval(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirm`,
        text: "Are you sure send for approval?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .post("/api/oms/v1/sfa-master/customer-address-approval/" + id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "Success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleEdit(id, status_approval) {
      this.$emit("edit", id, status_approval);
    },
  },
  watch: {
    statusFilter() {
      this.getData();
    },
    detail() {
      if (this.detail == false) {
        this.getData();
      }
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>